export const clearHTMLTags = (strToSanitize) => {
  // Remove all HTML tags
  let sanitizedString = strToSanitize.replace(/(<([^>]+)>)/gi, "");

  // Remove all occurrences of &nbsp;
  sanitizedString = sanitizedString.replace(/&nbsp;/g, "");

  // Trim the resulting string
  return sanitizedString.trim();
};
